<template>
  <div class="pageContol studentList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级结束提醒</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                v-model="retrievalData.projectCode"
                type="text"
                size="small"
                placeholder="请输入班级编号"
                onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
                clearable
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                v-model="retrievalData.projectName"
                type="text"
                size="small"
                placeholder="请输入班级名称"
                clearable
              />
            </div>
            <div title="开班机构" class="searchboxItem ci-full">
              <span class="itemLabel">开班机构:</span>
              <el-select
                size="small"
                v-model="retrievalData.compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in CompanyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <template v-if="!isInstitutions">
              <div title="行政区划" class="searchboxItem ci-full">
                  <span class="itemLabel" style="min-width:7rem">行政区划:</span>
                  <el-cascader
                          clearable
                          filterable
                          v-model="retrievalData.areaId"
                          :options="areatreeList"
                          :props="propsarea"
                          size="small"
                  ></el-cascader>
              </div>
              <div title="代理商" class="searchboxItem ci-full" v-if="userJson.roleId == '1' || userJson.roleId == '-1'">
                <span class="itemLabel" style="min-width: 6rem">代理商:</span>
                <el-select
                  size="small"
                  v-model="retrievalData.agencyId"
                  remote
                  :remote-method="getAgencyList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
                >
                  <el-option
                    v-for="item in AgencyList"
                    :key="item.agencyId"
                    :label="item.agencyName"
                    :value="item.agencyId"
                  ></el-option>
                </el-select>
              </div>
            </template>
          </div>
          <div class="searchbox">
            <div title="结束天数" class="searchboxItem ci-full">
              <span class="itemLabel">结束天数:</span>
              <el-input
                v-model="retrievalData.endDDayNum"
                type="text"
                size="small"
                placeholder="请输入结束天数（天）"
                clearable
                @keyup.native="
                  retrievalData.endDDayNum = zF.positiveInteger(
                    retrievalData.endDDayNum
                  )
                "
              />
            </div>
            <div title="是否提醒" class="searchboxItem ci-full">
              <span class="itemLabel">是否提醒:</span>
              <el-select
                size="small"
                v-model="retrievalData.remindState"
                clearable
                placeholder="请选择是否提醒"
              >
                <el-option
                  v-for="item in remindStateData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="结束日期" class="searchboxItem ci-full">
              <span class="itemLabel">结束日期:</span>
              <el-date-picker
                v-model="retrievalData.endTime"
                size="small"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                minWidth="180"
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                minWidth="180"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                minWidth="180"
              />
              <el-table-column
                v-if="userJson.roleId == '1' || userJson.roleId == '-1'"
                label="代理商"
                align="left"
                show-overflow-tooltip
                prop="agencyName"
                minWidth="180"
              />
              <el-table-column
                      label="行政区划"
                      align="left"
                      show-overflow-tooltip
                      prop="areaName"
                      minWidth="120"
              />
              <el-table-column
                label="培训有效期"
                align="left"
                prop="endDate"
                show-overflow-tooltip
                min-width="200"
              >
                <template slot-scope="scope">
                  {{ scope.row.startDate | momentDate }} 至 {{ scope.row.endDate | momentDate }}
                </template>
              </el-table-column>
              <el-table-column
                label="班级状态"
                align="left"
                prop="projectStudyState"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.projectStudyState == '10'">培训中</span>
                  <span v-if="scope.row.projectStudyState == '20'"
                    >培训结束</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="距结束天数"
                align="left"
                prop="surplusDay"
                show-overflow-tooltip
                min-width="100"
                ><template slot-scope="scope">
                  <span
                    :class="scope.row.surplusDay <= 3 ? 'ts_color_1' : ''"
                    >{{ scope.row.surplusDay }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="培训人数"
                align="left"
                show-overflow-tooltip
                prop="totalNum"
                minWidth="100"
              />
              <el-table-column
                label="结业人数"
                align="left"
                show-overflow-tooltip
                prop="graduationNum"
                minWidth="100"
              />
              <el-table-column
                label="学习完成人数"
                align="left"
                show-overflow-tooltip
                prop="completeNum"
                minWidth="150"
              />
              <el-table-column
                label="学习未完成人数"
                align="left"
                show-overflow-tooltip
                prop="uncompleteNum"
                minWidth="150"
              >
                <template slot-scope="scope">
                  <span class="ts_color_1">{{ scope.row.uncompleteNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="考试未通过人数"
                align="left"
                show-overflow-tooltip
                prop="unjoinExamNum"
                minWidth="150"
              >
                <template slot-scope="scope">
                  <span class="ts_color_1">{{ scope.row.unjoinExamNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="提问条数"
                align="left"
                show-overflow-tooltip
                prop="commentNum"
                minWidth="100"
              />
              <el-table-column
                label="答疑条数"
                align="left"
                show-overflow-tooltip
                prop="discussNum"
                minWidth="100"
              >
                <template slot-scope="scope">
                  <span class="ts_color_1">{{ scope.row.discussNum }}</span>
                </template>
              </el-table-column>
              <el-table-column
                label="备注"
                align="left"
                prop="remindNum"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  提醒
                  <span>{{
                    scope.row.remindNum ? scope.row.remindNum : 0
                  }}</span>
                  次
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="180px"
                fixed="right"
                ><template slot-scope="scope">
                  <el-button
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="setShortMessageRemindData(scope.row)"
                    >短信提醒</el-button
                  > 
                  <el-button
                    v-if="userJson.roleId == '1' || userJson.roleId == '-1'"
                    type="text"
                    style="padding: 0px 5px"
                    size="mini"
                    @click="seeHistoryRemindData(scope.row)"
                    >查看提醒历史</el-button
                  >
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <!-- 短信提醒 -->
    <el-dialog
      title="短信提醒"
      :visible.sync="shortMessageRemindData.docsDialogVisible"
      width="600px"
      top="10%"
      :center="true"
      :before-close="closeshortMessageRemindData"
    >
      <el-form
        ref="shortMessageRemindData"
        :model="shortMessageRemindData"
        :rules="shortMessageRemindDataRules"
        label-width="100px"
      >
        <el-form-item label="收信人" prop="remindMobile">
          <el-input
            type="text"
            size="small"
            placeholder="请输入收信人"
            v-model="shortMessageRemindData.remindMobile"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="提醒内容" prop="remindContent">
          <el-input
            class="remindContent"
            type="textarea"
            placeholder="请输入提醒内容"
            maxlength="100"
            show-word-limit
            v-model="shortMessageRemindData.remindContent"
          >
          </el-input>
          <p class="ts_color_1">每个班级每日可提醒1次，班级最多提醒3次 ！</p>
        </el-form-item>
      </el-form>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center"
      >
        <div>
          <el-button @click="closeshortMessageRemindData">取消</el-button>
          <el-button class="bgc-bv" @click="okShortMessageRemindData"
            >发送</el-button
          >
        </div>
      </span>
    </el-dialog>
    <!-- 查看提醒历史 -->
    <el-dialog
      title="查看提醒历史"
      :visible.sync="historyRemindData.docsDialogVisible"
      width="700px"
      top="10%"
      center
      :before-close="closeHistoryRemindData"
    >
      <el-table
        ref="historyRemindDataListData"
        :data="historyRemindData.listData"
        size="small"
        tooltip-effect="dark"
        :height="'400px'"
        :header-cell-style="tableHeader"
        stripe
      >
        <el-table-column
          label="序号"
          align="center"
          type="index"
          :index="indexMethod"
        />
        <el-table-column
          label="发送人"
          align="left"
          show-overflow-tooltip
          prop="createName"
          minWidth="100"
        />
        <el-table-column
          label="发送日期"
          align="left"
          show-overflow-tooltip
          prop="createTime"
          minWidth="120"
        />
        <el-table-column
          label="接收人(手机号)"
          align="left"
          show-overflow-tooltip
          prop="remindMobile"
          minWidth="100"
        />
        <el-table-column
          label="发送内容"
          align="left"
          show-overflow-tooltip
          prop="remindContent"
          minWidth="150"
        />
        <Empty slot="empty" />
      </el-table>
    </el-dialog>
  </div>
</template>
  <script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
export default {
  name: "classListCom_classEndReminder",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {

      // 区划 - 数据
      areatreeList: [],
      // 区划 - 字段规则
      propsarea: {
          value: "value",
          label: "label",
          emitPath: false,
          checkStrictly: true,
      },
      // 检索数据
      retrievalData: {
        projectCode: "", // 班级编码
        projectName: "", // 班级名称
        compId: "", // 开班机构
        agencyId: "", // 代理商
        endDDayNum: "", // 结算天数
        remindState: "", // 是否提醒
        endTime: [], // 结束时间
        areaId: "", // 班级名称
      },
      // 开班机构下拉数据
      CompanyList: [],
      // 代理商
      AgencyList: [],
      // 是否提醒数据
      remindStateData: [
        {
          value: "",
          label: "全部",
        },
        {
          value: true,
          label: "已提醒",
        },
        {
          value: false,
          label: "未提醒",
        },
      ],
      // 班级状态数据
      ClassstatusList: [],
      // 短信提醒 - 弹框数据
      shortMessageRemindData: {
        docsDialogVisible: false, // 弹框状态
        projectId: "", // 班级id
        remindMobile: "", // 收信人
        remindContent: "", // 短息信息
      },
      // 短信提醒 - 数据校验
      shortMessageRemindDataRules: {
        remindMobile: [
          {
            required: true,
            validator: this.$validatePhone,
            trigger: "blur",
          },
        ],
        remindContent: [
          { required: true, trigger: "blur", message: "请输入提醒内容" },
        ],
      },
      // 查看提醒历史 - 弹框数据
      historyRemindData: {
        docsDialogVisible: false, // 弹框状态
        listData: [], // 历史数据
      },
    };
  },
  created() {
    this.dataDictionary();
    this.getareatree();
  },
  watch: {},
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
    // 是否是机构端
    isInstitutions(){
      return this.userJson.roleId == '3'
    }
  },
  mounted() {},
  methods: {
    // 字典 - 区划
    getareatree() {
        this.$post("/sys/area/tree").then((ret) => {
            this.areatreeList = ret.data;
        });
    },
    // 获取数据字典（协议类型）
    dataDictionary() {
      const classStatusList = this.$setDictionary("PROJECTSTATE", "list");
      const list = [];
      list.push({
        value: "",
        label: "全部",
      });
      for (const key in classStatusList) {
        if (key == "" || key == "50" || key == "60") {
          list.push({
            value: key,
            label: classStatusList[key],
          });
        }
      }
      this.classStatusList = list;
    },
    // 获取 - 开班机构
    getCompanyList(query) {
      const userTypeUrl = {
        '1':'/sys/company/queryCompanyList',//运营
        '-1':'/sys/company/queryCompanyList',//运营
        '3':'/sys/company/contract/train/list',//机构
        '-5':'/sys/company/queryCompAgencyCompanyList',//代理
      } 
      if (query.trim().length >= 2) {
        this.$post(userTypeUrl[this.userJson.roleId], { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.CompanyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.CompanyList = [];
      }
    },
    // 获取 - 代理商
    getAgencyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/biz/mercantile/agency/getListByName", {
          agencyName: query,
        })
          .then((res) => {
            if (res.status == 0) {
              this.AgencyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.AgencyList = [];
      }
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.retrievalData.projectCode) {
        params.projectCode = this.retrievalData.projectCode;
      }
      if (this.retrievalData.projectName) {
        params.projectName = this.retrievalData.projectName;
      }
      if (this.retrievalData.compId) {
        params.compId = this.retrievalData.compId;
      }
      if (this.retrievalData.agencyId) {
        params.agencyId = this.retrievalData.agencyId;
      }
      if (this.retrievalData.areaId) {
          params.areaId = this.retrievalData.areaId;
      }

      if (this.retrievalData.endDDayNum) {
        params.endDDayNum = this.retrievalData.endDDayNum;
      }
      if (typeof this.retrievalData.remindState != "string") {
        params.remindState = this.retrievalData.remindState;
      }
      if (this.retrievalData.endTime) {
        params.startDate = this.retrievalData.endTime[0];
        params.endDate = this.retrievalData.endTime[1];
      }
      let url
      if(this.isInstitutions){
        url = '/biz/project/endRemind/comp/pageList'
      }else{
        url = '/biz/project/endRemind/pageList'
      }
      this.doFetch({
        url,
        params,
        pageNum,
      });
    },
    // 短信提醒 - 设置
    setShortMessageRemindData(row) {
      this.shortMessageRemindData.projectId = row.projectId;
      this.shortMessageRemindData.remindMobile = row.projectUserPhone;
      let surplusDay = ''; // 结束天数
      let uncompleteNum = ''; // 未学习完成数
      let unjoinExamNum = ''; // 未完成考试数
      let noDiscussNum = ''; // 答疑数

      surplusDay = '还有' + row.surplusDay + "天即将结束，"
      if (row.uncompleteNum > 0) {
        uncompleteNum = "尚有" + row.uncompleteNum + "人未学习完成，"
      }
      if (row.unjoinExamNum > 0) {
        unjoinExamNum = row.unjoinExamNum + "人已学完课程未考试，"
      }
      if((row.commentNum - row.discussNum)>0){
        noDiscussNum = `共有${row.commentNum}条提问，提问占比${row.commentProportion}%，`
        noDiscussNum += `未答疑${row.commentNum - row.discussNum}条，`
      }else{
        if(row.commentProportion<100){
          noDiscussNum = `共有${row.commentNum}条提问，提问占比${row.commentProportion}%，`
        }
      }
      this.shortMessageRemindData.remindContent =
        row.compName +
        "项目【" +
        row.projectName +
        "】" +
        surplusDay +
        uncompleteNum +
        unjoinExamNum +
        noDiscussNum +
        "请及时处理。";
      this.shortMessageRemindData.docsDialogVisible = true;
    },
    // 短信提醒 - 确定
    okShortMessageRemindData() {
      this.$refs["shortMessageRemindData"].validate((valid) => {
        if (valid) {
          this.$post("/biz/project/endRemind/insert", {
            projectId: this.shortMessageRemindData.projectId,
            remindMobile: this.shortMessageRemindData.remindMobile,
            remindContent: this.shortMessageRemindData.remindContent,
          })
            .then((res) => {
              if (res.status == 0) {
                this.getData(this.pageNum);
                this.closeshortMessageRemindData();
              }
            })
            .catch(() => {
              return;
            });
        }
      });
    },
    // 短信提醒 - 关闭
    closeshortMessageRemindData() {
      // 将data中的某个数据重置到初始状态(赵金福-专用方法);
      this.shortMessageRemindData = this.$options.data().shortMessageRemindData;
    },
    // 查看提醒历史 - 查看
    seeHistoryRemindData(row) {
      this.$post("/biz/project/endRemind/recordList", {
        projectId: row.projectId,
      })
        .then((res) => {
          if (res.status == 0) {
            this.historyRemindData.listData = res.data;
            this.historyRemindData.docsDialogVisible = true;
          }
        })
        .catch(() => {
          return;
        });
    },
    // 查看提醒历史 - 关闭
    closeHistoryRemindData() {
      // 将data中的某个数据重置到初始状态(赵金福-专用方法);
      this.historyRemindData = this.$options.data().historyRemindData;
    },
  },
};
</script>
  <style lang="less" scoped>
.ts_color_1 {
  color: red;
}
.searchbox {
  margin-bottom: 10px;
}
.remindContent {
  /deep/ .el-textarea__inner {
    height: 100px;
  }
}
</style>
  